import initialState from './initialState';
import { isEmptyObject } from '../../util/validator';
import { ASSOCIATION_TYPES } from '../../helpers/constants';
import {
  SELECT_SUVC_ASSOCIATION_FOR_USER,
  SELECT_VENDOR_ASSOCIATION_FOR_USER,
  SELECT_SUVC_ASSOCIATION_TYPE_FOR_USER,
  RECEIVE_VENDOR_ASSOCIATION_PERSIST_STATUS_FOR_USER,
  SELECT_VENDOR_ASSOCIATION_TYPE_FOR_USER,
  CHANGE_SUPERVISOR_FOR_USER,
  RECEIVE_SUPERVISOR_PERSIST_STATUS_FOR_USER,
  RECEIVE_SUPERVISOR_REMOVE_STATUS_FOR_USER,
  RECEIVE_USER_PROFILE_MANAGEMENT_DETAILS,
  RESET_USER_PROFILE_MANAGEMENT_DATA,
  RECEIVE_SUPPLIER_ONBOARD_DETAILS,
  RECEIVE_SUPPLIER_UPDATE_STATUS,
  RECEIVE_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS,
  RESET_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS,
  RECEIVE_SUPPLIER_PERSIST_STATUS
} from '../actions/userManagementActions';
import { RECEIVE_ERROR_IN_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_FETCH } from '../actions/errorDialogActions';

function handleAssociationTypeSelection(isSelected, data, selectedValue) {
  if (isSelected) {
    data.associationType = selectedValue;
  } else {
    data.associationType = '';
  }
}

function handlePartialSelectionsAtSuvcLevel(isAllSelected, suvcObj) {
  if (!isAllSelected) {
    suvcObj.associationType = '';
    suvcObj.vendors.forEach((vendor) => {
      if (vendor.associationType !== ASSOCIATION_TYPES.COSTING) {
        vendor.associationType = '';
      }
    });
  }
}

function selectVendorAssociationBasedOnSuvc(profile, data) {
  const suvcObj = profile.suvcList[data.row.suvc.code];
  let isAllSelected = true;
  let atLeastOneSelected = false;
  if (!isEmptyObject(suvcObj)) {
    profile.isVendorAssociationsChanged = true;
    suvcObj.vendors.forEach((vendor) => {
      if (vendor.code === data.row.code) {
        vendor.selected = data.value;
        handleAssociationTypeSelection(vendor.selected, vendor, '');
      }
      isAllSelected = isAllSelected && vendor.selected;
      atLeastOneSelected = atLeastOneSelected || vendor.selected;
    });
    handlePartialSelectionsAtSuvcLevel(isAllSelected, suvcObj);
    suvcObj.selected = isAllSelected;
    suvcObj.minOneSelected = atLeastOneSelected;
  }
}

function setSuvcAndVendorAssociationTypesWhenAllVendorsSelected(suvcObj, vendor, value) {
  if (suvcObj.selected) {
    vendor.associationType = value;
    suvcObj.associationType = value;
  }
}

function selectVendorAssociationtTypeBasedOnSuvc(profile, data) {
  const suvcObj = profile.suvcList[data.row.suvc.code];
  if (!isEmptyObject(suvcObj)) {
    suvcObj.vendors.forEach((vendor) => {
      if (data.row.selected && vendor.code === data.row.code) {
        vendor.associationType = data.value;
      }
      setSuvcAndVendorAssociationTypesWhenAllVendorsSelected(suvcObj, vendor, data.value);
    });
  }
}

function selectSuvcAssociation(profile, data) {
  const suvcObj = profile.suvcList[data.row.code];
  if (!isEmptyObject(suvcObj)) {
    profile.isVendorAssociationsChanged = true;
    suvcObj.vendors.forEach((vendor) => {
      vendor.selected = data.value;
      handleAssociationTypeSelection(vendor.selected, vendor, '');
    });
    suvcObj.selected = data.value;
    handleAssociationTypeSelection(suvcObj.selected, suvcObj, '');
    suvcObj.minOneSelected = data.value;
  }
}

function selectSuvcAssociationType(profile, data) {
  const suvcObj = profile.suvcList[data.row.code];
  if (!isEmptyObject(suvcObj)) {
    profile.isVendorAssociationsChanged = true;
    suvcObj.vendors.forEach((vendor) => {
      if (data.row.selected) {
        vendor.associationType = data.value;
      }
    });
    handleAssociationTypeSelection(data.row.selected, suvcObj, data.value);
  }
}

function getUserSuvcVendorAssociations(data, vendorAssociationMap) {
  const suvcVendorAssociationMap = {};
  data.forEach((suvc) => {
    const suvcObj = { ...suvc };
    let allSelected = true;
    let minOneSelected = false;
    let associationType = '';
    suvcObj.vendors = suvc.vendors.map((vendor) => {
      let selected = false;
      if (vendor.code in vendorAssociationMap) {
        selected = true;
        minOneSelected = true;
        associationType = vendorAssociationMap[vendor.code];
      } else {
        allSelected = false;
        associationType = '';
      }
      return ({
        ...vendor, suvc: { code: suvc.code, name: suvc.name }, associationType, selected,
      });
    });
    suvcObj.selected = allSelected;
    if (suvcObj.selected === true) {
      suvcObj.associationType = associationType;
    } else {
      suvcObj.associationType = '';
    }
    suvcObj.minOneSelected = minOneSelected;
    suvcVendorAssociationMap[suvcObj.code] = suvcObj;
  });
  return suvcVendorAssociationMap;
}

function setUserAssociations(data) {
  const userAssociations = {
    coordinatorContact: data?.coordinatorContact,
    sourcingContact: data?.sourcingContact,
    supplyChainContact: data?.supplyChainContact,
  };

  if (data?.backupCoordinatorAssociations) {
    data.backupCoordinatorAssociations.forEach(({ networkId, name, associationType }) => {
      if (associationType === 'BACKUP_COORDINATOR_1') {
        userAssociations.backupCoordinator1 = { networkId, name };
      } else if (associationType === 'BACKUP_COORDINATOR_2') {
        userAssociations.backupCoordinator2 = { networkId, name };
      }
    });
  }
  return userAssociations;
}

export default function userAssociationReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.userManagement;
  }

  switch (type) {
    case RECEIVE_VENDOR_ASSOCIATION_PERSIST_STATUS_FOR_USER: {
      return { ...state, profile: { ...state.profile, isVendorAssociationsChanged: false, initial: { ...state.profile.initial, suvcList: state.profile.suvcList } } };
    }
    case SELECT_SUVC_ASSOCIATION_FOR_USER: {
      const profile = { ...state.profile };
      selectSuvcAssociation(profile, data);
      return { ...state, profile };
    }
    case SELECT_VENDOR_ASSOCIATION_FOR_USER: {
      const profile = { ...state.profile };
      selectVendorAssociationBasedOnSuvc(profile, data);
      return { ...state, profile };
    }
    case SELECT_SUVC_ASSOCIATION_TYPE_FOR_USER: {
      const profile = { ...state.profile };
      selectSuvcAssociationType(profile, data);
      return { ...state, profile };
    }
    case SELECT_VENDOR_ASSOCIATION_TYPE_FOR_USER: {
      const profile = { ...state.profile };
      selectVendorAssociationtTypeBasedOnSuvc(profile, data);
      return { ...state, profile };
    }
    case RESET_USER_PROFILE_MANAGEMENT_DATA: {
      return initialState.userManagement;
    }
    case RECEIVE_USER_PROFILE_MANAGEMENT_DETAILS: {
      const vendorAssociationMap = {};
      data.vendorAssociations.forEach(({ vendor, associationType }) => {
        vendorAssociationMap[vendor] = associationType;
      });
      const suvcList = getUserSuvcVendorAssociations(data.suvcs, vendorAssociationMap);
      return {
        ...state,
        profile: {
          suvcList,
          supervisor: data.supervisor,
          isSupervisorChanged: false,
          isVendorAssociationsChanged: false,
          initial: { suvcList, supervisor: data.supervisor }
        },
      };
    }
    case RECEIVE_SUPERVISOR_PERSIST_STATUS_FOR_USER: {
      return {
        ...state,
        profile: {
          ...state.profile,
          isSupervisorChanged: false,
          initial: { ...state.profile.initial, supervisor: state.profile.supervisor }
        }
      };
    }
    case RECEIVE_SUPERVISOR_REMOVE_STATUS_FOR_USER: {
      const supervisor = {
        networkId: '',
        name: ''
      };
      return {
        ...state,
        profile: {
          ...state.profile,
          supervisor,
          isSupervisorChanged: false,
          initial: { ...state.profile.initial, supervisor }
        }
      };
    }
    case CHANGE_SUPERVISOR_FOR_USER: {
      let isSupervisorChanged = false;
      if (data.networkId !== state.profile.initial.supervisor?.networkId) {
        isSupervisorChanged = true;
      }
      return { ...state, profile: { ...state.profile, supervisor: data, isSupervisorChanged } };
    }
    case RECEIVE_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS: {
      const userAssociations = setUserAssociations(data);
      const existingBusinessCenterContacts = data.businessCenterContacts ? data.businessCenterContacts : [];
      return {
        ...state, userAssociations, existingBusinessCenterContacts
      };
    }
    case RECEIVE_SUPPLIER_ONBOARD_DETAILS: {
      const {
        firstName, lastName, networkId, email, phone
      } = data.supplierDetails;
      const { suvcList } = data;
      const supplierDetails = {
        firstName, lastName, email, phone, suvcList
      };
      return {
        ...state, existingSupplierEmail: networkId, supplierDetails,
      };
    }
    case RECEIVE_SUPPLIER_UPDATE_STATUS: {
      return { ...state, existingSupplierEmail: data };
    }
    case RESET_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS:
    case RECEIVE_ERROR_IN_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_FETCH: {
      return {
        ...state, existingSupplierEmail: '', supplierDetails: {}, userAssociations: {}, existingBusinessCenterContacts: []
      };
    }
    case RECEIVE_SUPPLIER_PERSIST_STATUS:
      return {
        ...state, newUserId: data.data
      };
    default:
      return state;
  }
}
