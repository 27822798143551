/**
 * Created by aheshperera
 * 12/3/18
 * */

/**
 * React Modules
 */
import { combineReducers } from 'redux';
/**
 * Custom Reducers
 */
import bisFileReducer from './bisFileReducer';
import cookieReducer from './cookieReducer';
import fileTransferDetailsReducer from './fileTransferDetailsReducer';
import loaderReducer from './loaderReducer';
import vendorFileReducer from './vendorFileReducer';
import successReducer from './successReducer';
import errorReducer from './errorReducer';
import filesLogReducer from './filesLogReducer';
import userReducer from './userReducer';
import reportsLogReducer from './reportsLogReducer';
import usersReducer from './usersReducer';
import userProfileReducer from './userProfileReducer';
import userAssociationReducer from './userAssociationReducer';
import userGroupReducer from './userGroupReducer';
import vendorAssociationFileImportReducer from './vendorAssociationFileImportReducer';
import agreementFileReducer from './agreementFileReducer';
import vendorDataReducer from './vendorDataReducer';
import vendorFilterDataReducer from './vendorFilterDataReducer';
import costAuditDataReducer from './costAuditDataReducer';
import reportSupplementaryDataReducer from './reportSupplementaryDataReducer';
import contactUsReducer from './contactUsReducer';
import helpTopicsReducer from './helpTopicsReducer';
import recentActivityReducer from './recentActivityReducer';
import adhocCostDataReducer from './adhocCostDataReducer';
import requestDataReducer from './requestDataReducer';
import workspaceSearchReducer from './workspaceSearchReducer';
import enterpriseCostReducer from './enterpriseCostReducer';
import agreementDataReducer from './agreementDataReducer';
import utilitiesReducer from './utilitiesReducer';
import secretsReducer from './secretsReducer';

const rootReducer = combineReducers({
  fileReducer: bisFileReducer,
  fileTransferDetailsReducer,
  loaderReducer,
  cookieReducer,
  vendorFileReducer,
  agreementFileReducer,
  errorReducer,
  filesLogReducer,
  successReducer,
  userReducer,
  reportsLogReducer,
  usersReducer,
  secretsReducer,
  userManagementReducer: combineReducers({ userAssociationReducer, userGroupReducer, vendorAssociationFileImportReducer }),
  userProfileReducer,
  enterpriseCostReducer,
  agreementDataReducer,
  utilitiesReducer,
  vendorReducer: combineReducers({ vendorDataReducer, vendorFilterDataReducer }),
  reportReducer: combineReducers({ costAuditDataReducer, adhocCostDataReducer, reportSupplementaryDataReducer }),
  workspaceReducer: combineReducers({
    contactUsReducer, helpTopicsReducer, recentActivityReducer, requestDataReducer, workspaceSearchReducer
  }),
});

export default rootReducer;
