import React from 'react';
import Loadable from 'react-loadable';
// eslint-disable-next-line import/no-cycle
import { PERMISSIONS } from './helpers/constants';

/**
 * Define routes
 *
 * @author aheshperera
 */

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard/dashboard'),
  loading: Loading,
});

const Files = Loadable({
  loader: () => import('./views/FileExplorer/fileExplorer'),
  loading: Loading,
});

const FilesTransferDetails = Loadable({
  loader: () => import('./views/FileTransferDetails/fileTransferDetailsReport'),
  loading: Loading,
});

const VendorFileImportView = Loadable({
  loader: () => import('./views/VendorFileImport/vendorFileImportView'),
  loading: Loading,
});

const MassAgreementImport = Loadable({
  loader: () => import('./views/MassAgreement/massAgreementImport'),
  loading: Loading,
});

const Base12RequestImport = Loadable({
  loader: () => import('./views/MassAgreement/base12RequestImport'),
  loading: Loading,
});

const FilesLog = Loadable({
  loader: () => import('./views/FilesLog/filesLog'),
  loading: Loading,
});

const EnterpriseCostView = Loadable({
  loader: () => import('./views/EnterpriseCost/costView'),
  loading: Loading,
});

const PcpVendorCostLog = Loadable({
  loader: () => import('./views/PCPFilesLog/pcpVendorCost/pcpVendorCostLog'),
  loading: Loading,
});

const VendorAgreementsLog = Loadable({
  loader: () => import('./views/PCPFilesLog/pcpAgreements/vendorAgreementsLog'),
  loading: Loading,
});

const UserProfile = Loadable({
  loader: () => import('./baseContainer/user/profile/userProfile'),
  loading: Loading,
});

const ProfileManagement = Loadable({
  loader: () => import('./views/UserManagement/profileManagement/profileManagement'),
  loading: Loading,
  render(loaded, props) {
    const ProfileManagementComponent = loaded.default;

    const activeTabProps = props.match.path.includes('/user-management/profile-management/edit/:id')
      ? { activeTab: 'tab_3' }
      : { activeTab: (props.location.state || 'tab_1') };

    return <ProfileManagementComponent {...props} {...activeTabProps} />;
  },
});

const UserDirectory = Loadable({
  loader: () => import('./views/UserManagement/userDirectory/userDirectory'),
  loading: Loading,
});

const CostAuditExplorer = Loadable({
  loader: () => import('./views/Reports/CostAudit/costAuditExplorer'),
  loading: Loading,
});

const CostFileExplorer = Loadable({
  loader: () => import('./views/CostMaintenance/CostFile/costFileExplorer'),
  loading: Loading,
});

const AdhocCostChangeExplorer = Loadable({
  loader: () => import('./views/CostMaintenance/AdhocCostChange/adhocCostChangeExplorer'),
  loading: Loading,
});

const CatalogCostChangeExplorer = Loadable({
  loader: () => import('./views/CostMaintenance/CatalogCostChange/catalogCostChangeExplorer'),
  loading: Loading,
});

const RequestOverview = Loadable({
  loader: () => import('./views/Dashboard/RequestOverview/requestOverview'),
  loading: Loading,
});

const CostReportsTabView = Loadable({
  loader: () => import('./views/Dashboard/RequestOverview/dataGrid/costReportsTabView'),
  loading: Loading,
});

const WorkspaceSearchView = Loadable({
  loader: () => import('./views/Search/workspaceSearch'),
  loading: Loading,
});

const MappingControlView = Loadable({
  loader: () => import('./views/Utilities/mappingControlView'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/enterprise-cost-view',
    name: 'Enterprise Cost View',
    exact: true,
    component: EnterpriseCostView,
    permissions: [PERMISSIONS.VIEW_ENTERPRISE_COST_DATA]
  },
  {
    path: '/', exact: true, name: 'Home', component: Dashboard, permissions: [PERMISSIONS.VIEW_COST_DATA_MANAGEMENT_DASHBOARD]
  },
  {
    path: '/requests/:id', exact: true, name: 'Request Overview', component: RequestOverview, permissions: [PERMISSIONS.VIEW_COST_DATA_MANAGEMENT_DASHBOARD]
  },
  {
    path: '/requests/:id/adhoc-cost-data',
    exact: true,
    name: 'Adhoc Cost Data',
    component: CostReportsTabView,
    permissions: [PERMISSIONS.VIEW_ADHOC_COST_CHANGE]
  },
  {
    path: '/requests/:id/commodity-cost-data',
    exact: true,
    name: 'Commodity Cost Data',
    component: CostReportsTabView,
    permissions: [PERMISSIONS.VIEW_ADHOC_COST_CHANGE]
  },
  {
    path: '/requests/:id/sygma-cost-data',
    exact: true,
    name: 'Sygma Cost Data',
    component: CostReportsTabView,
    permissions: [PERMISSIONS.VIEW_ADHOC_COST_CHANGE]
  },
  {
    path: '/requests/:id/bis-cost-data',
    exact: true,
    name: 'BIS Cost Data',
    component: CostReportsTabView,
    permissions: [PERMISSIONS.VIEW_ADHOC_COST_CHANGE]
  },
  {
    path: '/bis-files',
    name: 'BIS File Explorer',
    component: Files,
    permissions: [PERMISSIONS.VIEW_BIS_FILE_EXPLORER]
  },
  {
    path: '/file-transfer-details',
    name: 'File Transfer Details',
    component: FilesTransferDetails,
    permissions: [PERMISSIONS.VIEW_FILE_TRANSFER_LOG]
  },
  {
    path: '/vendor-file-import',
    name: 'Vendor File Import',
    component: VendorFileImportView,
    permissions: [
      PERMISSIONS.IMPORT_VENDOR_COST_FILE,
      PERMISSIONS.CREATE_VARIATION_REPORT,
    ]
  }, {
    path: '/agreement-import',
    name: 'Agreement Request Import',
    component: MassAgreementImport,
    permissions: [
      PERMISSIONS.IMPORT_AGREEMENT_REQUEST
    ]
  },
  {
    path: '/base12-import',
    name: 'Base 12 Request Import',
    component: Base12RequestImport,
    permissions: [
      PERMISSIONS.IMPORT_AGREEMENT_REQUEST
    ]
  },
  {
    path: '/file-explorer',
    name: 'File Explorer',
    component: FilesLog,
    permissions: [PERMISSIONS.VIEW_EXPLORER]
  },
  {
    path: '/file-explorer-pcp/vendor-cost',
    name: 'File Explorer - PC Pricing  /  Vendor Cost',
    component: PcpVendorCostLog,
    permissions: [PERMISSIONS.VIEW_PCP_EXPLORER]
  },
  {
    path: '/file-explorer-pcp/vendor-agreements',
    name: 'File Explorer - PC Pricing  /  Vendor Agreements',
    component: VendorAgreementsLog,
    permissions: [PERMISSIONS.VIEW_PCP_EXPLORER]
  },
  { path: '/user/profile', name: 'My Profile', component: UserProfile },
  {
    path: '/reports/cost-audit',
    name: 'Reports / Cost Audit',
    component: CostAuditExplorer,
    permissions: [PERMISSIONS.EXECUTE_COST_AUDIT, PERMISSIONS.EXECUTE_LIMITED_COST_AUDIT]
  },
  {
    path: '/cost-maintenance/cost-file',
    name: 'Cost Maintenance / Cost File',
    component: CostFileExplorer,
    permissions: [PERMISSIONS.EXECUTE_COST_FILE, PERMISSIONS.EXECUTE_LIMITED_COST_FILE]
  },
  {
    path: '/cost-maintenance/adhoc-cost-change',
    name: 'Cost Maintenance / Adhoc Cost Change',
    component: AdhocCostChangeExplorer,
    permissions: [PERMISSIONS.INITIATE_ADHOC_COST_CHANGE]
  },
  {
    path: '/cost-maintenance/catalog-cost-change',
    name: 'Cost Maintenance / Catalog Cost Change',
    component: CatalogCostChangeExplorer,
    permissions: [PERMISSIONS.INITIATE_CATALOG_COST_CHANGE]
  },
  {
    path: '/user-management/user-directory',
    name: 'User Management / User Directory',
    component: UserDirectory,
    permissions: [PERMISSIONS.VIEW_USER_DIRECTORY]
  },
  {
    path: '/user-management/profile-management/edit/:id',
    exact: true,
    component: ProfileManagement,
    permissions: [PERMISSIONS.SUPPLIER_PROFILE_EDIT]
  },
  {
    path: '/user-management/profile-management',
    exact: true,
    name: 'User Management / Profile Management',
    component: ProfileManagement,
    permissions: [PERMISSIONS.USER_MAINTENANCE, PERMISSIONS.VIEW_USER_DIRECTORY]
  },
  {
    path: '/search/workspace-search',
    name: 'Search / Workspace',
    component: WorkspaceSearchView
  },
  {
    path: '/utilities/mapping-control',
    name: 'Utilities / Mapping Control',
    component: MappingControlView,
    permissions: [PERMISSIONS.VIEW_UTILITIES]
  },
];

export default routes;
