import axios from 'axios';
import { URLS, HTTP_METHODS } from '../helpers/constants';

/**
 * Fetch data from APIs related to agreements
 * @author skit1169
 */
class AgreementProxy {
  static async fetchAgreementSearchData(data) {
    const url = `${URLS.AGREEMENT_SEARCH_REQUEST_URL}`;
    const config = {
      url,
      method: HTTP_METHODS.POST,
      data
    };
    const response = await axios(config);
    return response.data;
  }
}

export default AgreementProxy;
