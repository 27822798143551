/**
 * Created by aheshperera
 * 12/3/18
 * */

import config from '../config';

// BFF Base URL
export const BFF_API_BASE_URL = config.bff.URL;

const PC_PRICING_APPS = config.app.PCP;
const RME_URL = config.app.RME;
const SALESFORCE_URL = config.app.SALESFORCE;
const CDM_PUBLIC_URL = config.app.CDM_PUBLIC;
const { OKTA_PASSWORD_RESET_URL } = config.app;

// BFF URLs
export const URLS = {
  GET_COOKIE_URL: `${BFF_API_BASE_URL}/auth/getCookie`,
  BIS_FILES_URL: `${BFF_API_BASE_URL}/files/bis`,
  FILE_TRANSFER_DETAILS_URL: `${BFF_API_BASE_URL}/files/transfer-details`,
  ATTACHMENT_DOWNLOAD_URL: `${BFF_API_BASE_URL}/files/download/attachment`,
  VENDOR_FILE_IMPORT_URL: `${BFF_API_BASE_URL}/vendor-file-import`,
  AGREEMENT_FILE_IMPORT_URL: `${BFF_API_BASE_URL}/agreements/import`,
  UPLOAD_SIGNED_URL_GENERATION_URL: `${BFF_API_BASE_URL}/files/signed-url/upload`,
  DOWNLOAD_SIGNED_URL_GENERATION_URL: `${BFF_API_BASE_URL}/files/signed-url/download`,
  VENDOR_PRICE_FILES_IMPORT_LOG_URL: `${BFF_API_BASE_URL}/files/log/vendor-price-import`,
  FILES_DOWNLOAD_URL: `${BFF_API_BASE_URL}/files/download`,
  USER_DETAILS_URL: `${BFF_API_BASE_URL}/user/details`,
  DATADOG_SECRETS_URL: `${BFF_API_BASE_URL}/secrets/datadog-secrets`,
  USER_PROFILE_DETAILS_URL: `${BFF_API_BASE_URL}/user/profile`,
  MY_PROFILE_DETAILS_URL: `${BFF_API_BASE_URL}/user/myprofile`,
  USER_PROFILE_MANAGEMENT_DETAILS_URL: `${BFF_API_BASE_URL}/user/management/profile`,
  USER_SUPERVISOR_ASSOCIATION_URL: `${BFF_API_BASE_URL}/user/management/profile/supervisor`,
  USER_VENDORS_ASSOCIATION_URL: `${BFF_API_BASE_URL}/user/management/profile/vendors`,
  USER_VENDORS_ASSOCIATION_FILE_IMPORT_URL: `${BFF_API_BASE_URL}/user/management/profile/internal/vendors/import`,
  ALL_USER_GROUPS_URL: `${BFF_API_BASE_URL}/user/groups`,
  USER_GROUPS_URL: `${BFF_API_BASE_URL}/user/management/profile/groups`,
  GET_USER_GROUP_ASSOCIATIONS_URL: `${BFF_API_BASE_URL}/user/management/profile/group-associations`,
  USER_ASSOCIATION_AND_BUSINESS_CENTERS_CONTACTS_URL: `${BFF_API_BASE_URL}/vendors/user-associations`,
  USERS_URL: `${BFF_API_BASE_URL}/users`,
  REPORT_DETAILS_URL: `${BFF_API_BASE_URL}/reports/log`,
  REPORT_DATA_URL: `${BFF_API_BASE_URL}/reports/grid`,
  REPORT_METADATA_URL: `${BFF_API_BASE_URL}/reports/metadata`,
  REPORT_TEMPLATES_URL: `${BFF_API_BASE_URL}/reports/templates`,
  COST_DATA_URL: `${BFF_API_BASE_URL}/cost-data`,
  VENDORS_FETCH_URL: `${BFF_API_BASE_URL}/vendors`,
  WORKSPACE_SUPPLIER_SUPPORT_REQUEST_SUBMISSION_URL: `${BFF_API_BASE_URL}/workspace/supplier-support-request/submit`,
  WORKSPACE_SUPPLIER_SUPPORT_REQUEST_TYPES_URL: `${BFF_API_BASE_URL}/workspace/supplier-support-request/types`,
  WORKSPACE_HELP_TOPICS_URL: `${BFF_API_BASE_URL}/workspace/help-topics`,
  WORKSPACE_HELP_TOPIC_DETAILS_URL: `${BFF_API_BASE_URL}/workspace/help-topic-details`,
  WORKSPACE_RECENT_ACTIVITIES_URL: `${BFF_API_BASE_URL}/workspace/recent-activities`,
  WORKSPACE_REQUEST_SUBMISSION_URL: `${BFF_API_BASE_URL}/workspace/workspace-request/submit`,
  WORKSPACE_REQUEST_DETAILS_URL: `${BFF_API_BASE_URL}/workspace/requests`,
  MASTER_DATA_URL: `${BFF_API_BASE_URL}/master-data`,
  LOGIN_URL: `${BFF_API_BASE_URL}/auth/login`,
  LOGOUT_URL: `${BFF_API_BASE_URL}/auth/logout`,
  CDM_PUBLIC_URL: `${CDM_PUBLIC_URL}/login`,
  ENTERPRISE_COST_URL: `${BFF_API_BASE_URL}/enterprise-data`,
  AGREEMENT_SEARCH_REQUEST_URL: `${BFF_API_BASE_URL}/agreements/agreement-item-detail/search`,
  EARNED_INCOME_MAPPINGS_REQUEST_URL: `${BFF_API_BASE_URL}/utilities/earned-income-mappings`,
  SUPPLIER_PROFILE_DETAILS_URL: `${BFF_API_BASE_URL}/supplier/profile`,
  SUPPLIER_ONBOARD_DETAILS_URL: `${BFF_API_BASE_URL}/supplier/profile/onboard-details`,
  UPDATE_SUPPLIER_CONTACTS_URL: `${BFF_API_BASE_URL}/supplier/supplier-contacts`,
};

export const EXTERNAL_APPS_URLS = {
  PCP_P1_URL: PC_PRICING_APPS.P1,
  PCP_P2_URL: PC_PRICING_APPS.P2,
  RME_URL,
  SALESFORCE_URL,
  OKTA_PASSWORD_RESET_URL
};

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH'
};

export const HTTP_CONTENT_TYPE = {
  APPLICATION_OCTET_STREAM: 'application/octet-stream',
  APPLICATION_JSON: 'application/json',
};

export const VENDOR_FILE_PROCESS_TYPES = {
  CREATE_VARIATION_REPORT: 'CREATE_VARIATION_REPORT',
  PRICE_LOAD: 'PRICE_LOAD'
};

export const FILE_TYPES = {
  CORPORATE_PROGRAM: 'CORPORATE_PROGRAM',
  VENDOR_PRICE_FILE: 'VENDOR_PRICE_FILE',
  GENERAL_WORKSPACE_ATTACHMENT: 'GENERAL_WORKSPACE_ATTACHMENT',
  TEMP_WORKSPACE_DATA_OBJECT: 'TEMP_WORKSPACE_DATA_OBJECT',
  ADHOC_COST_FILE: 'ADHOC_COST_FILE',
  ADHOC_COST_FILE_MASS_UPLOAD: 'ADHOC_COST_FILE_MASS_UPLOAD',
  BASE_12_REQUEST: 'BASE_12_REQUEST',
  VENDOR_ASSOCIATION_FILE_IMPORT_REQUEST: 'VENDOR_ASSOCIATION_FILE_IMPORT_REQUEST',
  VENDOR_ASSOCIATION_MASS_UPDATE_WORKSHEET_TEMPLATE: 'VENDOR_ASSOCIATION_MASS_UPDATE_WORKSHEET_TEMPLATE',
};

export const CATALOG_COST_CHANGE_FILE_TYPES = {
  DEAR_VALUED_CUSTOMER_LETTER: {
    id: 'DEAR_VALUED_CUSTOMER_LETTER',
    label: 'Dear Valued Customer Letter',
    fileTypeLabel: '(.docx or .pdf)',
    maxFileCount: 1,
    maxFileSizeInMegabytes: 1,
    maxCumulativeFileSizeInMegabytes: 1,
    acceptedFileFormats: {
      value: 'application/pdf, .docx',
      label: 'Word (.docx) or PDF'
    },
    description: 'Signed price change letter informing ALL customers across ALL channels, including retail. (Max 1 file, up to 1MB)'
  },
  SUPPLIER_COST_CHANGE_IMPACT_WORKSHEET: {
    id: 'SUPPLIER_COST_CHANGE_IMPACT_WORKSHEET',
    label: 'Supplier Cost Change Impact',
    fileTypeLabel: '(.xls or .xlsx)',
    maxFileCount: 2,
    maxFileSizeInMegabytes: 10,
    maxCumulativeFileSizeInMegabytes: 20,
    templateId: 'SUPPLIER_COST_CHANGE_IMPACT_WORKSHEET_TEMPLATE',
    acceptedFileFormats: {
      value: '.xls, .xlsx',
      label: 'Excel (.xls or .xlsx)'
    },
    description: 'Current vs. New Pricing with Associated Cost Impact. Template is available, if needed. (Max 2 files, up to 10MB each)'
  },
  SYSCO_COST_FILE: {
    id: 'SYSCO_COST_FILE',
    label: 'Sysco Cost File(s)',
    fileTypeLabel: '(.csv)',
    maxFileCount: 2,
    maxFileSizeInMegabytes: 5,
    maxCumulativeFileSizeInMegabytes: 10,
    acceptedFileFormats: {
      value: '.csv',
      label: 'CSV'
    },
    description: 'Sysco Cost file with New Pricing (Max 2 files, up to 5MB each)'
  },
  NATIONAL_PRICE_LIST_WORKSHEET: {
    id: 'NATIONAL_PRICE_LIST_WORKSHEET',
    label: 'National Price List',
    fileTypeLabel: '(.xls or .xlsx)',
    maxFileCount: 1,
    maxFileSizeInMegabytes: 5,
    maxCumulativeFileSizeInMegabytes: 5,
    acceptedFileFormats: {
      value: '.xls, .xlsx',
      label: 'Excel (.xls or .xlsx)'
    },
    description: 'National Price List (Max 1 file, up to 5MB)'
  },
  SUPPORTING_JUSTIFICATION_DOCUMENTS: {
    id: 'SUPPORTING_JUSTIFICATION_DOCUMENTS',
    label: 'Supporting Justification Documents: Market Reports, Indexes for Commodities, etc.',
    fileTypeLabel: '(.docx, .pdf or .pptx)',
    maxFileCount: 4,
    maxFileSizeInMegabytes: 20,
    maxCumulativeFileSizeInMegabytes: 20,
    acceptedFileFormats: {
      value: 'application/pdf, .docx, .pptx',
      label: 'Word (.docx), PDF or PowerPoint (.pptx)'
    },
    description: 'Provide backup documentation justifying the price increase (Max 4 files, up to 20MB total)'
  },
  SYSCO_COST_COMPONENT_WORKSHEET: {
    id: 'SYSCO_COST_COMPONENT_WORKSHEET',
    label: 'Sysco Cost Component Worksheet',
    fileTypeLabel: '(.xlsx)',
    maxFileCount: 1,
    maxFileSizeInMegabytes: 3,
    maxCumulativeFileSizeInMegabytes: 3,
    templateId: 'SYSCO_COST_COMPONENT_WORKSHEET_TEMPLATE',
    acceptedFileFormats: {
      value: '.xlsx',
      label: 'Excel (.xlsx)'
    },
    description: 'Worksheet to analyze cost drivers - List key ingredients, packaging, overhead etc. Be as detailed as possible." Required Template available. (Max 1 file, up to 3MB each)'
  },
  DISCONTINUED_SKU_WORKSHEET: {
    id: 'DISCONTINUED_SKU_WORKSHEET',
    label: 'Inactive/Discontinued SKUs',
    fileTypeLabel: '(.xls or .xlsx)',
    maxFileCount: 1,
    maxFileSizeInMegabytes: 2,
    maxCumulativeFileSizeInMegabytes: 2,
    acceptedFileFormats: {
      value: '.xls, .xlsx',
      label: 'Excel (.xls or .xlsx)'
    },
    description: 'List of Discontinued or Inactive Items (Max 1 file, up to 2MB)'
  },
};

export const SERVER_TYPE = {
  P1: 'P1',
  P2: 'P2'
};

export const PROCESS_STATUS_TYPES = {
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE'
};

export const ROLES = {
  ENTERPRISE_COST_READ_ONLY_ASSOCIATE: 'EC-RO',
  COORDINATOR: 'CRDN',
  SPECIALIST: 'SPCL',
  SOURCING_SPECIALIST: 'SC-SPCL',
  SOURCING_LEADER: 'SC-LEAD',
  CATEGORY_MANAGEMENT_SPECIALIST: 'CAT-SPCL',
  CATEGORY_MANAGEMENT_LEADER: 'CAT-LEAD',
};

export const PERMISSIONS = {
  VIEW_ALL_TRANSACTIONS: 'VEW-ALL',
  ASSOCIATED_TRANSACTION_VIEW: 'VEW-ASC',
  VIEW_PCP_FILES_FROM_S3: 'VEW-PCF',
  USER_MAINTENANCE: 'USR-MNT',
  USER_GROUP_MAINTENANCE: 'GRP-MNT',
  VIEW_DIRECT_REPORTS: 'VEW-DRP',
  VIEW_EXPLORER: 'VEW-EXP',
  VIEW_PCP_EXPLORER: 'VEW-EXP-PCP',
  VIEW_FILE_TRANSFER_LOG: 'VEW-FTL',
  VIEW_BIS_FILE_EXPLORER: 'VEW-EXP-BIS',
  IMPORT_VENDOR_COST_FILE: 'IMP-VCF',
  CREATE_VARIATION_REPORT: 'CRE-VAR',
  VIEW_COST_DATA_MANAGEMENT_DASHBOARD: 'VEW-CMD',
  IMPORT_AGREEMENT_REQUEST: 'IMP-AGR',
  VIEW_USER_DIRECTORY: 'VEW-UDR',
  EXECUTE_COST_AUDIT: 'EXC-CAD',
  MANAGE_TEMPLATE: 'MNG-TPL',
  EXECUTE_LIMITED_COST_AUDIT: 'CAD-ASC',
  EXECUTE_COST_FILE: 'CFR-ALL',
  EXECUTE_LIMITED_COST_FILE: 'CFR-ASC',
  UPDATE_WORKSPACE_PROCESS_STATUS: 'UPT-WPS',
  INITIATE_ADHOC_COST_CHANGE: 'CRE-ACM',
  VIEW_ADHOC_COST_CHANGE: 'VEW-ACM',
  INITIATE_CATALOG_COST_CHANGE: 'CRE-CCM',
  VIEW_ENTERPRISE_COST_DATA: 'VEW-ECS',
  VIEW_UTILITIES: 'VEW-UTL',
  SUPPLIER_PROFILE_CREATE: 'CRE-SUP',
  SUPPLIER_PROFILE_EDIT: 'MOD-SUP',
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE', INACTIVE: 'INACTIVE'
};

export const USER_FETCH_TYPES = {
  ALL: 'all', SUPERVISORS: 'supervisors', INTERNAL: 'internal'
};

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  OK: 200,
  TEMPORARY_REDIRECT: 307
};

export const IDENTITY_PROVIDER = {
  AZURE_AD: 'AzureAD',
  OKTA: 'Okta',
};

export const HOUSE_TYPES = {
  GLOBAL: 'GLOBAL',
  RDC: 'RDC'
};

export const BUSINESS_CENTERS = {
  BAKE: 'Bakery',
  BEEF: 'Beef',
  BEV: 'Beverage',
  BFPRPD: 'Beef Prepared',
  BRAND: 'Branded',
  CAN: 'Can',
  CANNED: 'Canned',
  CHEM: 'Chemicals',
  CONV: 'Convenience',
  DAIRY: 'Dairy',
  DDAIRY: 'DDairy Market',
  DISP: 'Disposable',
  EPROD: 'E Produced',
  ESALES: 'E Sales',
  FATS: 'Fats',
  GROC: 'Grocery',
  MED: 'Medicine',
  NPROD: 'National Produced',
  NPURC: 'National Purchased',
  PLTRY: 'Poultry',
  PORK: 'Pork',
  POT: 'Potato',
  PROD: 'Produced',
  SEAFD: 'Seafood',
  SUPP: 'Supplier Equipments',
  TXPROD: 'TX Produced',
  VEG: 'Vegetables'
};

export const PRICE_TYPE_ABBREVIATIONS = {
  GLOBAL: 'G',
  HOUSE: 'H',
  CONTRACT: 'C',
  REGION: 'L',
  BRACKET: 'B',
};

export const PRICE_TYPES = {
  GLOBAL: 'GLOBAL',
  HOUSE: 'HOUSE',
  CONTRACT: 'CONTRACT',
  BRACKET: 'BRACKET',
  REGION: 'REGION',
};

export const VENDOR_SELL_METHODS = {
  FOB: 'FOB',
  DELIVERED: 'DELIVERED',
};

export const VENDOR_SELL_METHOD_ABBREVIATIONS = {
  FOB: 'CP',
  DELIVERED: 'DP',
};

export const REPORT_TYPES = {
  COST_AUDIT_GRID: 'COST_AUDIT_GRID',
};

export const DATA_TYPES = {
  DATE: 'DATE',
  NUMBER: 'NUMBER',
  TEXT: 'TEXT'
};

export const USER_TYPES = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL'
};

export const CALCULATION_METHOD = {
  COST_PLUS: 'Cost Plus',
  SELL_MINUS: 'Sell Minus',
  SPC: 'SPC',
  COST_PLUS_VARIATION: 'Cost Plus Variation',
  SQUEEZE: 'Squeeze',
};

export const REQUEST_TYPES = {
  SUPPLIER_SUPPORT_REQUEST: 'SUPPLIER_SUPPORT_REQUEST',
  VENDOR_COST_FILE: 'VENDOR_COST_FILE',
  VENDOR_COST_AUDIT: 'VENDOR_COST_AUDIT',
  VENDOR_AGREEMENT_AUDIT: 'VENDOR_AGREEMENT_AUDIT',
  VENDOR_COST_ADHOC: 'VENDOR_COST_ADHOC',
  SYGMA_COST_CHANGE: 'SYGMA_COST_CHANGE',
  VENDOR_COST_CATALOG: 'VENDOR_COST_CATALOG',
  BIS_COST_CHANGE: 'BIS_COST_CHANGE',
  COMMODITY_COST_CHANGE: 'COMMODITY_COST_CHANGE',
};

export const REQUEST_STATUS_TYPES = {
  RECEIVED: 'RECEIVED',
  REVIEW_IP: 'REVIEW_IP',
  COMPLETED: 'COMPLETED',
  DECLINED: 'DECLINED',
  APPROVED: 'APPROVED',
  REVIEWED: 'REVIEWED',
  REVIEW_COMPLETED: 'REVIEW_COMPLETED',
  RESOLVED: 'RESOLVED',
  PENDING_PRICE_LOAD: 'PENDING_PRICE_LOAD',
  PENDING_LEAD_APPROVAL: 'PENDING_LEAD_APPROVAL'
};

export const REQUEST_ACTION_TYPES = {
  SUBMIT: 'SUBMIT',
  VIEW: 'VIEW',
  MODIFY: 'MODIFY',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  RESOLVE: 'RESOLVE',
  EXECUTE_VARIATION: 'EXECUTE_VARIATION',
  COMMENT: 'COMMENT',
  ASSIGNMENT: 'ASSIGNMENT',
  CALCULATE: 'CALCULATE',
  REVIEWED: 'REVIEWED',
};

export const COST_RECORD_STATUS = {
  DECLINED: 'DECLINED',
  APPROVED: 'APPROVED',
  STAGED: 'STAGED',
  REVIEW_IP: 'REVIEW_IP',
  LOADED: 'LOADED'
};

export const EARNED_INCOME_BUCKETS = {
  BASE_MAPPINGS: {
    BASE1: 'Vendor Disc',
    BASE2: 'Vendor Allow',
    BASE3: 'SMR',
    BASE4: 'OPCO Fund',
    BASE5: 'Overhead',
    BASE6: 'Brand Manager',
    BASE7: 'Promo',
    BASE8: 'Cash Disc Diff',
    BASE9: 'Additional SMR',
    BASE10: 'QA',
    BASE11: 'Base 11',
    BASE12: 'Base 12',
    BASE13: 'OPCO Promo Fund',
  },
  OPCO_EXCEPTIONS: {
    OPCO1: 'Market SMR',
    OPCO2: 'Owned Equip',
    OPCO3: 'Leased Equip',
    OPCO4: 'Marketing Fund',
    OPCO5: 'Misc Opco 1',
    OPCO6: 'Misc Opco 2',
  },
  WAREHOUSE_EXCEPTIONS: {
    WHSE1: 'Handling',
    WHSE2: 'Storage',
    WHSE3: 'Interest',
    WHSE4: 'Freight In',
    WHSE5: 'Misc Whse 1',
    WHSE6: 'Misc Whse 2',
  }
};

export const COSTING_ERROR = {
  COST_FORCED_DOWN: 'Cost Forced Down',
};

export const ASSIGNEE_TYPES = {
  INTERNAL_REQUEST_OWNER: 'INTERNAL_REQUEST_OWNER',
  REVIEWER: 'REVIEWER',
  APPROVER: 'APPROVER',
  getValue(key) {
    if (key === 0) return this.INTERNAL_REQUEST_OWNER;
    if (key === 1) return this.REVIEWER;
    if (key === 2) return this.APPROVER;
    return 'Invalid Key';
  }
};

export const P1_PRICE_RECORD_TYPES = {
  GLOBAL: 'GLOBAL',
  SYSCO_HOUSE: 'SYSCO HOUSE',
  SYGMA_HOUSE: 'SYGMA HOUSE',
  CONTRACT: 'CONTRACT'
};

export const FILE_EXTENSIONS = {
  PDF: 'pdf',
  EXCEL: 'xlsx',
  MS_EXCEL: 'application/vnd.ms-excel',
  PNG: 'png',
  JPEG: 'jpeg',
  JPG: 'jpg',
  WORD: 'docx',
  CSV: 'csv',
  POWERPOINT: 'pptx'
};

export const FILE_MIME_TYPE = {
  CSV: '.csv,text/csv',
  EXCEL: '.xlsx,application/vnd.ms-excel',
};

export const OWNED_RECORDS_INDICATOR = {
  SET: 'SET',
  UNSET: 'UNSET',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

export const TRANSFORM_HTTP_MESSAGE = {
  ALLOW: 'allow',
  DENY: 'deny',
};

export const DATETIME_FORMAT = {
  MMDDYYYY_HHMMSS: 'MM/DD/YYYY HH:mm:ss',
  MMMM_DD_YYYY_HHMMSS_A: 'MMMM DD,YYYY hh:mm:ss a',
  YYYYMMDD_HHMMSS: 'YYYY-MM-DD HH:mm:ss',
  YYYYMMDD: 'YYYY-MM-DD',
  MMMM_DD_YYYY: 'MMMM DD, YYYY',
};

export const CHARACTER = {
  SLASH: '/',
  DASH: '-'
};

export const CHANGE_VALUE_BASIS_TYPES = {
  PERCENTAGE: '%',
  CS: 'CS',
  LB: 'LB',
  NTWT: 'NTWT',
};

export const PRICE_CHANGE_CATEGORIES = {
  BAKE: 'Bakery',
  BEEF_BFPRPD: 'Beef/Beef Prepared',
  BEV: 'Beverage',
  CANNED: 'Canned',
  CHEM: 'Chemical',
  CONV: 'Convenience',
  DAIRY: 'Dairy',
  DISP: 'Disposables',
  FATS_OILS: 'Fats/Oils',
  FRZNVEG_POT: 'Frozen Veg/Potato',
  GROC: 'Grocery',
  PORK: 'Pork',
  PLTRY: 'Poultry',
  SEAFD: 'Seafood',
};

export const ASSOCIATION_TYPES = {
  COSTING: 'COSTING',
  LEAD: 'LEAD',
  SOURCING: 'SOURCING'
};

export const MAX_PAYLOAD_SIZE_IN_BYTES = 943720;
export const US_DATE_FORMAT = 'mm-dd-yyyy';
export const MILLISECONDS_PER_MINUTE = 60000;

export const COST_HEADER_INFO = [
  { label: 'Row ID', field: 'rowId' },
  { label: 'Vendor', field: 'supplier' },
  { label: 'Item', field: 'item' },
  { label: 'Business Center', field: 'businessCenter' },
  { label: 'House', field: 'house' },
  { label: 'Warehouse', field: 'warehouse' },
  { label: 'SMS Cost', field: 'smsCost' },
  { label: 'SMS Sell', field: 'marketPrice' },
  { label: 'Net Base', field: 'netBase' },
];

export const AGREEMENT_SUMMARY_INFO = [
  { label: 'Agreement Number', field: 'agreementNumber', showLabelToolTip: true },
  {
    label: 'Agreement Description', field: 'agreementDescription', showLabelToolTip: true, showValueToolTip: true
  },
  { label: 'Effective Date', field: 'effectiveDate' },
  {
    label: 'Calculation Method', field: 'calculationMethod', showLabelToolTip: true, showValueToolTip: true
  },
  { label: 'Decimal Places', field: 'decimalPlaces' },
  { label: 'Rounding Method', field: 'roundingMethod', showLabelToolTip: true },
  { label: 'Last Updated By', field: 'lastUpdatedBy' },
  { label: 'Last Updated On', field: 'lastUpdatedDate', showValueToolTip: true },
];

export const ITEM_GROUP_INFO = [
  { label: 'Group', field: 'itemGroup' },
  { label: 'Description', field: 'itemGroupDescription', showValueToolTip: true },
  { label: 'Source Flag', field: 'sourcedItem' },
  { label: 'Category Managed', field: 'categoryManaged', showLabelToolTip: true },
  { label: 'Commodity Flag', field: 'commodityItem' }
];

export const OPCO_EXCEPTION_INFO = [
  { label: 'OPCO Code', field: 'opcoCode' },
  { label: 'OPCO Name', field: 'opcoName' }
];

export const WHSE_EXCEPTION_INFO = [
  { label: 'Warehouse Code', field: 'warehouseCode' },
  { label: 'Warehouse Name', field: 'warehouseName', showLabelToolTip: true }
];

export const SAS_FD_INFO = [
  { label: 'SAS Amount', field: 'sasAmount' },
  { label: 'FD Amount', field: 'fdAmount', showLabelToolTip: true }
];

export const ADHOC_DATA_GRID_HEADERS = [
  {
    id: 'BUSINESS_CENTER',
    accessor: 'businessCenter',
    displayName: 'Business Center',
    type: 'TEXT'
  },
  {
    id: 'SUVC',
    accessor: 'suvc',
    displayName: 'SUVC',
    type: 'TEXT'
  },
  {
    id: 'SUPPLIER',
    accessor: 'supplier',
    displayName: 'Supplier Vendor Number',
    type: 'TEXT'
  },
  {
    id: 'WAREHOUSE',
    accessor: 'warehouse',
    displayName: 'Warehouse',
    type: 'TEXT'
  },
  {
    id: 'SUPC',
    accessor: 'item',
    displayName: 'SUPC',
    type: 'TEXT'
  },
  {
    id: 'MFG_ID',
    accessor: 'manufactureId',
    displayName: 'MFG-ID',
    type: 'TEXT'
  },
  {
    id: 'BRACKET',
    accessor: 'bracket',
    displayName: 'Bracket',
    type: 'TEXT'
  },
  {
    id: 'OPCO',
    accessor: 'opco',
    displayName: 'OpCo',
    type: 'TEXT'
  },
  {
    id: 'EFFECTIVE_DATE',
    accessor: 'effectiveDate',
    displayName: 'Effective Date',
    type: 'DATE'
  },
  {
    id: 'CURRENT_SMS_COST',
    accessor: 'currentSmsCost',
    displayName: 'Current Sysco Cost',
    type: 'NUMBER'
  },
  {
    id: 'SMS_COST',
    accessor: 'smsCost',
    displayName: 'Sysco Cost',
    type: 'NUMBER'
  },
  {
    id: 'SMS_COST_DIFF',
    accessor: 'smsCostDiff',
    displayName: '% Difference Sysco Cost',
    type: 'NUMBER'
  },
  {
    id: 'CURRENT_MARKET_PRICE',
    accessor: 'currentMarketPrice',
    displayName: 'Current Market Price',
    type: 'NUMBER'
  },
  {
    id: 'MARKET_PRICE',
    accessor: 'marketPrice',
    displayName: 'Market Price',
    type: 'NUMBER'
  },
  {
    id: 'MARKET_PRICE_DIFF',
    accessor: 'marketPriceDiff',
    displayName: '% Difference Market Price',
    type: 'NUMBER'
  },
  {
    id: 'BUY_BY',
    accessor: 'buyBy',
    displayName: 'Buy By',
    type: 'TEXT'
  },
  {
    id: 'SELL_BY',
    accessor: 'sellBy',
    displayName: 'Sell By',
    type: 'TEXT'
  },
  {
    id: 'NET_WEIGHT',
    accessor: 'netWeight',
    displayName: 'Net Weight',
    type: 'TEXT'
  },
  {
    id: 'PACK',
    accessor: 'pack',
    displayName: 'Pack',
    type: 'TEXT'
  },
  {
    id: 'SIZE',
    accessor: 'itemSize',
    displayName: 'Size',
    type: 'TEXT'
  },
  {
    id: 'BRAND',
    accessor: 'brand',
    displayName: 'Brand',
    type: 'TEXT'
  },
  {
    id: 'DESCRIPTION',
    accessor: 'description',
    displayName: 'Description',
    type: 'TEXT'
  },
  {
    id: 'HOUSE_CODE',
    accessor: 'house',
    displayName: 'House Code',
    type: 'TEXT'
  },
  {
    id: 'CALC_METHOD',
    accessor: 'calculationMethod',
    displayName: 'Calculation Method',
    type: 'TEXT'
  },
  {
    id: 'SOURCE_INDICATOR',
    accessor: 'sourced',
    displayName: 'Sourced',
    type: 'TEXT'
  },
  {
    id: 'OWNED_INDICATOR',
    accessor: 'owned',
    displayName: 'Owned',
    type: 'TEXT'
  },
];

export const SUGGESTED_SQUEEZE_GRID_HEADERS = [
  {
    colId: 'SQUEEZE_AMOUNT',
    headerName: 'Squeeze Amount',
    field: 'squeezedAmount',
    minWidth: 140,
  },
  {
    colId: 'SUGGESTED_MARKET_PRICE',
    headerName: 'Suggested Market Price',
    field: 'suggestedSell',
    minWidth: 140,
  }
];

export const ORIGINAL_COST_SELL_INPUT_GRID_HEADERS = [
  {
    colId: 'ORIGINAL_MARKET_PRICE',
    field: 'originalInputMarketPrice',
    headerName: 'Original Market Price',
    type: 'NUMBER',
    minWidth: 140,
  },
  {
    colId: 'ORIGINAL_SMS_COST',
    field: 'originalInputSmsCost',
    headerName: 'Original Sysco Cost',
    type: 'NUMBER',
    minWidth: 140,
  }
];

export const INVALID_RECORDS_GRID_HEADERS = [
  {
    id: 'ERROR_MESSAGE',
    accessor: 'errorMessage',
    displayName: 'Error Message',
    type: 'TEXT',
    columnWidth: 300,
    pinColumn: 'right',
  },
  {
    id: 'BUSINESS_CENTER',
    accessor: 'businessCenter',
    displayName: 'Business Center',
    type: 'TEXT'
  },
  {
    id: 'SUPPLIER',
    accessor: 'supplier',
    displayName: 'Supplier Vendor Number',
    type: 'TEXT'
  },
  {
    id: 'WAREHOUSE',
    accessor: 'warehouse',
    displayName: 'Warehouse',
    type: 'TEXT'
  },
  {
    id: 'SUPC',
    accessor: 'item',
    displayName: 'SUPC',
    type: 'TEXT'
  },
  {
    id: 'BRACKET',
    accessor: 'bracket',
    displayName: 'Bracket',
    type: 'TEXT'
  },
  {
    id: 'OPCO',
    accessor: 'opco',
    displayName: 'OpCo',
    type: 'TEXT'
  },
  {
    id: 'EFFECTIVE_DATE',
    accessor: 'effectiveDate',
    displayName: 'Effective Date',
    type: 'TEXT'
  },
  {
    id: 'SMS_COST',
    accessor: 'smsCost',
    displayName: 'Sysco Cost',
    type: 'TEXT'
  },
  {
    id: 'MARKET_PRICE',
    accessor: 'marketPrice',
    displayName: 'Market Price',
    type: 'TEXT'
  },
  {
    id: 'HOUSE_CODE',
    accessor: 'house',
    displayName: 'House Code',
    type: 'TEXT'
  },
];

export const KEY_DISPLAY_COLUMN_HEADERS = {
  rowId: 'Row ID',
  supplier: 'Supplier Vendor Number',
  warehouse: 'Warehouse',
  item: 'SUPC',
  bracket: 'Bracket/Contract',
  effectiveDate: 'Effective Date',
  smsCost: 'Sysco Cost',
  marketPrice: 'Market Price',
  house: 'House Code',
  calculationMethod: 'Calculation Method'
};

export const KEY_COLUMN_ID = {
  rowId: 'ROW_ID',
  supplier: 'SUPPLIER',
  warehouse: 'WAREHOUSE',
  item: 'SUPC',
  bracket: 'BRACKET',
  effectiveDate: 'EFFECTIVE_DATE',
  smsCost: 'SMS_COST',
  marketPrice: 'MARKET_PRICE',
  house: 'HOUSE_CODE',
  calculationMethod: 'CALC_METHOD'
};

export const BASE12_FILE_COLUMN_HEADERS = [
  'Vendor',
  'House',
  'Business Center',
  'Agreement Number',
  'Base12 Value'
];

export const COST_STAGING_ADHOC_DATA_GRID_HEADERS = [
  {
    id: KEY_COLUMN_ID.rowId,
    accessor: 'rowId',
    displayName: KEY_DISPLAY_COLUMN_HEADERS.rowId,
    type: 'TEXT',
    minWidth: 80,
    pinColumn: 'left',
  },
  {
    id: 'BUSINESS_CENTER',
    accessor: 'businessCenter',
    displayName: 'Business Center',
    type: 'TEXT'
  },
  {
    id: 'SUVC',
    accessor: 'suvc',
    displayName: 'SUVC',
    type: 'TEXT',
    hide: true,
  },
  {
    id: KEY_COLUMN_ID.supplier,
    accessor: 'supplier',
    displayName: KEY_DISPLAY_COLUMN_HEADERS.supplier,
    type: 'TEXT',
    headerTooltip: true,
  },
  {
    id: KEY_COLUMN_ID.warehouse,
    accessor: 'warehouse',
    displayName: KEY_DISPLAY_COLUMN_HEADERS.warehouse,
    type: 'TEXT'
  },
  {
    id: KEY_COLUMN_ID.item,
    accessor: 'item',
    displayName: KEY_DISPLAY_COLUMN_HEADERS.item,
    type: 'TEXT'
  },
  {
    id: 'MFG_ID',
    accessor: 'manufactureId',
    displayName: 'MFG-ID',
    type: 'TEXT'
  },
  {
    id: KEY_COLUMN_ID.bracket,
    accessor: 'bracket',
    displayName: KEY_DISPLAY_COLUMN_HEADERS.bracket,
    type: 'TEXT'
  },
  {
    id: 'OPCO',
    accessor: 'opco',
    displayName: 'OpCo',
    type: 'TEXT'
  },
  {
    id: KEY_COLUMN_ID.effectiveDate,
    accessor: 'effectiveDate',
    displayName: KEY_DISPLAY_COLUMN_HEADERS.effectiveDate,
    type: 'DATE'
  },
  {
    id: 'OLD_BSCC_SPC_COST',
    accessor: 'currentCalculatedCost',
    displayName: 'Current Calculated Cost',
    type: 'NUMBER',
    headerTooltip: true,
  },
  {
    id: 'NEW_BSCC_SPC_COST',
    accessor: 'calculatedCost',
    displayName: 'Calculated Cost',
    type: 'NUMBER'
  },
  {
    id: 'BSCC_SPC_COST_DIFF',
    accessor: 'calculatedCostDiff',
    displayName: '% Diff of Calculated Cost',
    type: 'NUMBER',
    headerTooltip: true,
  },
  {
    id: 'CURRENT_SMS_COST',
    accessor: 'currentSmsCost',
    displayName: 'Current Sysco Cost',
    type: 'NUMBER',
    headerTooltip: true,
  },
  {
    id: KEY_COLUMN_ID.smsCost,
    accessor: 'smsCost',
    displayName: KEY_DISPLAY_COLUMN_HEADERS.smsCost,
    type: 'NUMBER'
  },
  {
    id: 'SMS_COST_DIFF',
    accessor: 'smsCostDiff',
    displayName: '% Difference Sysco Cost',
    type: 'NUMBER',
    headerTooltip: true,
  },
  {
    id: 'CURRENT_MARKET_PRICE',
    accessor: 'currentMarketPrice',
    displayName: 'Current Market Price',
    type: 'NUMBER',
    headerTooltip: true,
  },
  {
    id: KEY_COLUMN_ID.marketPrice,
    accessor: 'marketPrice',
    displayName: KEY_DISPLAY_COLUMN_HEADERS.marketPrice,
    type: 'NUMBER'
  },
  {
    id: 'MARKET_PRICE_DIFF',
    accessor: 'marketPriceDiff',
    displayName: '% Difference Market Price',
    type: 'NUMBER',
    headerTooltip: true,
  },
  {
    id: 'BUY_BY',
    accessor: 'buyBy',
    displayName: 'Buy By',
    type: 'TEXT'
  },
  {
    id: 'SELL_BY',
    accessor: 'sellBy',
    displayName: 'Sell By',
    type: 'TEXT'
  },
  {
    id: 'NET_WEIGHT',
    accessor: 'netWeight',
    displayName: 'Net Weight',
    type: 'TEXT'
  },
  {
    id: 'PACK',
    accessor: 'pack',
    displayName: 'Pack',
    type: 'TEXT'
  },
  {
    id: 'SIZE',
    accessor: 'itemSize',
    displayName: 'Size',
    type: 'TEXT'
  },
  {
    id: 'BRAND',
    accessor: 'brand',
    displayName: 'Brand',
    type: 'TEXT'
  },
  {
    id: 'DESCRIPTION',
    accessor: 'description',
    displayName: 'Description',
    type: 'TEXT'
  },
  {
    id: KEY_COLUMN_ID.house,
    accessor: 'house',
    displayName: KEY_DISPLAY_COLUMN_HEADERS.house,
    type: 'TEXT'
  },
  {
    id: KEY_COLUMN_ID.calculationMethod,
    accessor: 'calculationMethod',
    displayName: KEY_DISPLAY_COLUMN_HEADERS.calculationMethod,
    type: 'TEXT',
    headerTooltip: true,
  },
  {
    id: 'SOURCE_INDICATOR',
    accessor: 'sourced',
    displayName: 'Sourced',
    type: 'TEXT'
  },
  {
    id: 'OWNED_INDICATOR',
    accessor: 'owned',
    displayName: 'Owned',
    type: 'TEXT'
  },
  {
    id: 'DISPLAY_MODE',
    accessor: 'displayMode',
    displayName: 'Display Mode',
    type: 'TEXT'
  },
  {
    id: 'WEIGHT_INDICATOR',
    accessor: 'weightIndicator',
    displayName: 'Weight Indicator',
    type: 'TEXT'
  },
  {
    id: 'GROSS_WEIGHT',
    accessor: 'grossWeight',
    displayName: 'Gross Weight',
    type: 'TEXT'
  },
  {
    id: 'MESSAGE',
    accessor: 'message',
    displayName: 'Status',
    type: 'TEXT',
    minWidth: 140,
    pinColumn: 'right',
  },
  {
    id: 'ITEM_REJECT',
    accessor: 'rejected',
    displayName: 'Rejected',
    minWidth: 90,
    pinColumn: 'right',
  },
  {
    id: 'COMMENT',
    accessor: 'comment',
    displayName: 'Comment',
    type: 'TEXT',
    minWidth: 120,
    pinColumn: 'right',
  }
];

export const ADHOC_VARIATION_DATA_GRID_HEADERS = [
  {
    id: 'ROW_ID',
    accessor: 'rowId',
    displayName: 'Row ID',
    type: 'TEXT'
  },
  {
    id: 'BUSINESS_CENTER',
    accessor: 'businessCenter',
    displayName: 'Business Center',
    type: 'TEXT'
  },
  {
    id: 'HOUSE_CODE',
    accessor: 'house',
    displayName: 'House Code',
    type: 'TEXT'
  },
  {
    id: 'BRACKET',
    accessor: 'bracket',
    displayName: 'Bracket',
    type: 'TEXT'
  },
  {
    id: 'SUPC',
    accessor: 'item',
    displayName: 'SUPC',
    type: 'TEXT'
  },
  {
    id: 'WAREHOUSE',
    accessor: 'warehouse',
    displayName: 'Warehouse',
    type: 'TEXT'
  },
  {
    id: 'SUPPLIER',
    accessor: 'supplier',
    displayName: 'Supplier Vendor Number',
    type: 'TEXT'
  },
  {
    id: 'OPCO_NAME',
    accessor: 'opco',
    displayName: 'OpCo',
    type: 'TEXT'
  },
  {
    id: 'MFG_ID',
    accessor: 'manufactureId',
    displayName: 'MFG-ID',
    type: 'TEXT'
  },
  {
    id: 'BRAND',
    accessor: 'brand',
    displayName: 'Brand',
    type: 'TEXT'
  },
  {
    id: 'PACK',
    accessor: 'pack',
    displayName: 'Pack',
    type: 'TEXT'
  },
  {
    id: 'SIZE',
    accessor: 'itemSize',
    displayName: 'Size',
    type: 'TEXT'
  },
  {
    id: 'DISPLAY_MODE',
    accessor: 'displayMode',
    displayName: 'Display Mode',
    type: 'TEXT'
  },
  {
    id: 'WEIGHT_INDICATOR',
    accessor: 'weightIndicator',
    displayName: 'Weight Indicator',
    type: 'TEXT'
  },
  {
    id: 'DESCRIPTION',
    accessor: 'description',
    displayName: 'Description',
    type: 'TEXT'
  },
  {
    id: 'NET_WEIGHT',
    accessor: 'netWeight',
    displayName: 'Net Weight',
    type: 'TEXT'
  },
  {
    id: 'GROSS_WEIGHT',
    accessor: 'grossWeight',
    displayName: 'Gross Weight',
    type: 'TEXT'
  },
  {
    id: 'EFFECTIVE_DATE',
    accessor: 'effectiveDate',
    displayName: 'Effective Date',
    type: 'DATE'
  },
  {
    id: 'OLD_VENDOR_COST',
    accessor: 'oldVendorCost',
    displayName: 'Old Vendor Cost',
    type: 'NUMBER'
  },
  {
    id: 'NEW_VENDOR_COST',
    accessor: 'newVendorCost',
    displayName: 'New Vendor Cost',
    type: 'NUMBER'
  },
  {
    id: 'VENDOR_COST_DIFF',
    accessor: 'vendorCostDiff',
    displayName: '% Difference of Vendor Cost',
    type: 'NUMBER'
  },
  {
    id: 'OLD_BSCC_SPC_COST',
    accessor: 'oldBsccSpcCost',
    displayName: 'Old BSCC SPC Cost',
    type: 'NUMBER'
  },
  {
    id: 'NEW_BSCC_SPC_COST',
    accessor: 'newBsccSpcCost',
    displayName: 'New BSCC SPC Cost',
    type: 'NUMBER'
  },
  {
    id: 'BSCC_SPC_COST_DIFF',
    accessor: 'bsccSpcCostDiff',
    displayName: '% Diff of BSCC SPC Cost',
    type: 'NUMBER'
  },
  {
    id: 'OLD_SELL/MARKET_PRICE',
    accessor: 'oldSellMarketPrice',
    displayName: 'Old Sell/Market Price',
    type: 'NUMBER'
  },
  {
    id: 'NEW_SELL/MARKET_PRICE',
    accessor: 'newSellMarketPrice',
    displayName: 'New Sell/Market Price',
    type: 'NUMBER'
  },
  {
    id: 'SELL/MARKET_PRICE_DIFF',
    accessor: 'bsccSpcCostDiff',
    displayName: '% Diff of Sell/Market Price',
    type: 'NUMBER'
  },
  {
    id: 'MESSAGE',
    accessor: 'message',
    displayName: 'MESSAGE',
    type: 'TEXT'
  },
];

export const ADHOC_EXCEPTION_DATA_GRID_HEADERS = [
  {
    id: 'ROW_ID',
    accessor: 'rowId',
    displayName: 'Row ID',
    type: 'TEXT'
  },
  {
    id: 'SUPPLIER',
    accessor: 'supplier',
    displayName: 'Supplier Vendor Number',
    type: 'TEXT'
  },
  {
    id: 'BUSINESS_CENTER',
    accessor: 'businessCenter',
    displayName: 'Business Center',
    type: 'TEXT'
  },
  {
    id: 'SUPC',
    accessor: 'item',
    displayName: 'SUPC',
    type: 'TEXT'
  },
  {
    id: 'WAREHOUSE',
    accessor: 'warehouse',
    displayName: 'Warehouse',
    type: 'TEXT'
  },
  {
    id: 'OPCO_NAME',
    accessor: 'opcoAbbreviation',
    displayName: 'OpCo',
    type: 'TEXT'
  },
  {
    id: 'HOUSE_CODE',
    accessor: 'houseCode',
    displayName: 'House Code',
    type: 'TEXT'
  },
  {
    id: 'BEGIN_DATE',
    accessor: 'beginningDate',
    displayName: 'Beginning Date',
    type: 'DATE'
  },
  {
    id: 'END_DATE',
    accessor: 'endDate',
    displayName: 'End Date',
    type: 'DATE'
  },
  {
    id: 'SELL',
    accessor: 'sell',
    displayName: 'Sell',
    type: 'NUMBER'
  },
  {
    id: 'COST',
    accessor: 'cost',
    displayName: 'Cost',
    type: 'NUMBER'
  },
  {
    id: 'CALCULATED_COST',
    accessor: 'calculatedCost',
    displayName: 'Calculated Cost',
    type: 'NUMBER'
  },
  {
    id: 'EXCEPTION',
    accessor: 'exception',
    displayName: 'Exception',
    type: 'TEXT'
  }
];

export const ADHOC_NOT_IMPORTED_DATA_GRID_HEADERS = [
  {
    id: 'ROW_ID',
    accessor: 'rowId',
    displayName: 'Row ID',
    type: 'TEXT'
  },
  {
    id: 'BUSINESS_CENTER',
    accessor: 'businessCenter',
    displayName: 'Business Center',
    type: 'TEXT'
  },
  {
    id: 'SUPC',
    accessor: 'item',
    displayName: 'Item',
    type: 'TEXT'
  },
  {
    id: 'USER_ID',
    accessor: 'userId',
    displayName: 'User ID',
    type: 'TEXT'
  },
  {
    id: 'SUPPLIER',
    accessor: 'supplier',
    displayName: 'Supplier',
    type: 'TEXT'
  },
  {
    id: 'PRICE_CODE',
    accessor: 'priceCode',
    displayName: 'Price Code',
    type: 'TEXT'
  }
];

export const ADHOC_SUB_REQUESTS_GRID_COLUMNS = [
  {
    Header: '',
    accessor: 'view_request',
    sortable: false,
    filterable: false,
    maxWidth: 50,
  },
  {
    Header: 'Sub Request ID',
    accessor: 'sub_request_id',
    minWidth: 140,
  }, {
    Header: 'Business Center',
    accessor: 'business_center',
    minWidth: 110,
  },
  {
    Header: 'Status',
    accessor: 'sub_request_status',
    minWidth: 140,
  },
  {
    Header: 'Updated On',
    accessor: 'updated_on',
    minWidth: 130,
  },
  {
    Header: 'Approvers',
    accessor: 'approvers',
    sortable: false,
    filterable: false,
    minWidth: 190,
  },
  {
    accessor: 'messages',
    sortable: false,
    filterable: false,
    minWidth: 140,
  },
  {
    Header: 'Actions',
    accessor: 'request_actions',
    sortable: false,
    filterable: false,
    minWidth: 180,
    style: { whiteSpace: 'unset' }
  },
  {
    accessor: 'cost_data_records',
    sortable: false,
    filterable: false,
    minWidth: 130,
  }
];

export const DEFAULT_SUB_REQUESTS_GRID_COLUMNS = [
  {
    Header: '',
    accessor: 'view_request',
    sortable: false,
    filterable: false,
    maxWidth: 50,
  },
  {
    Header: 'Sub Request ID',
    accessor: 'sub_request_id',
    minWidth: 140,
  },
  {
    Header: 'Status',
    accessor: 'sub_request_status',
    minWidth: 140,
  },
  {
    Header: 'Updated On',
    accessor: 'updated_on',
    minWidth: 130,
  },
  {
    accessor: 'messages',
    sortable: false,
    filterable: false,
    minWidth: 140,
  }
];

export const COST_CHANGE_DRIVERS = {
  PRODUCT_COST_CHANGE: {
    label: 'Product Cost Change',
    value: 'PRODUCT_COST_CHANGE',
    additionalDataType: 'SUB_OPTIONS',
    subOptions: {
      COMMODITY_MARKET_CHANGE: { label: 'Commodity Market Change', value: 'COMMODITY_MARKET_CHANGE' },
      RAW_MATERIAL_COST: { label: 'Raw Material Cost', value: 'RAW_MATERIAL_COST' },
      MARKET_COMPETITION: { label: 'Market Competition', value: 'MARKET_COMPETITION' },
    },
  },
  PRICE_CORRECTION: {
    label: 'Price Correction',
    value: 'PRICE_CORRECTION'
  },
  MISSED_ITEM_ON_PREV_APPROVED_CHANGE: {
    label: 'Missed Item on Previous Approved Change',
    value: 'MISSED_ITEM_ON_PREV_APPROVED_CHANGE',
    additionalDataType: 'DATE'
  },
  AVAILABILITY_SUB_REPLACE_ITEM: {
    label: 'Availability Sub/Replace Item',
    value: 'AVAILABILITY_SUB_REPLACE_ITEM'
  },
  PROPRIETARY_COST_CHANGE: {
    label: 'Proprietary Cost Change',
    value: 'PROPRIETARY_COST_CHANGE'
  },
  AGREEMENT_CHANGE: {
    label: 'Agreement Change',
    value: 'AGREEMENT_CHANGE'
  },
  NEW_ITEMS: {
    label: 'New Item(s)',
    value: 'NEW_ITEMS'
  }
};

export const VARIANCE_LIMITS = {
  UPPER_LIMIT: 50,
  LOWER_LIMIT: 20
};

export const WORKSPACE_SEARCH = {
  WORKSPACE_SEARCH_TYPE: {
    NORMAL_SEARCH: 'NORMAL_SEARCH',
    ADVANCED_SEARCH: 'ADVANCED_SEARCH'
  },
  DEFAULT_SEARCH_TYPE: 'NORMAL_SEARCH',
  DEFAULT_SPAN: '3',
  CHANGE_SET: {
    VENDOR_COST_ADHOC: 'VENDOR COST ADHOC (VCI)',
    VENDOR_COST_CATALOG: 'SUPPLIER CATALOG CHANGE (VCC)',
    COMMODITY_COST_CHANGE: 'COMMODITY COST CHANGE (COM)',
    REVIEW_IP: 'REVIEW IN PROGRESS'
  },
  EXCLUDED_REQUEST_TYPES: [REQUEST_TYPES.VENDOR_AGREEMENT_AUDIT],
  EXCLUDED_REQUEST_STATUS_TYPES: [REQUEST_STATUS_TYPES.REVIEWED],
  EXTERNAL_USER_EXCLUDED_REQUEST_TYPES: [REQUEST_TYPES.BIS_COST_CHANGE, REQUEST_TYPES.SYGMA_COST_CHANGE, REQUEST_TYPES.COMMODITY_COST_CHANGE],
  EXTERNAL_USER_EXCLUDED_REQUEST_STATUS_TYPES: []
};
