/**
 * Created by ysum5308
 * */
import initialState from './initialState';
import {
  REQUEST_COST_DATA,
  RECEIVE_COST_DATA,
  REQUEST_ADHOC_COST_FILE_IMPORT,
  RECEIVE_ADHOC_COST_FILE_IMPORT_RESPONSE,
  RESET_ADHOC_COST_DATA,
} from '../actions/costMaintenanceActions';
import {
  RECEIVE_ERROR_IN_COST_DATA_FETCH,
  RECEIVE_ERROR_IN_ADHOC_COST_FILE_IMPORT
} from '../actions/errorDialogActions';

function handleItemValidationRequest(params) {
  return { ...params.state, itemValidationResponse: { ...params.state.itemValidationResponse, loading: true } };
}

function handleItemValidationResponse(params) {
  return {
    ...params.state,
    itemValidationResponse: {
      loading: false,
      invalidItems: params.data.invalidItems,
      errorMessage: params.data.errorMessage
    }
  };
}

function handleItemValidationResponseError(params) {
  return { ...params.state, itemValidationResponse: { ...params.state.itemValidationResponse, loading: false } };
}

function handleItemValidationResponseReset(params) {
  return { ...params.state, itemValidationResponse: { invalidItems: [], errorMessage: '', loading: false } };
}

const itemValidationHandlers = {
  VALIDATE_ITEMS_BY_SUPCS: handleItemValidationRequest,
  RECEIVE_ITEM_VALIDATION_RESPONSE: handleItemValidationResponse,
  RECEIVE_ERROR_IN_ITEMS_VALIDATION: handleItemValidationResponseError,
  RESET_ITEM_VALIDATION_RESPONSE: handleItemValidationResponseReset,
};

export default function adhocCostDataReducer(state, { type, data }) {
  if (typeof state === 'undefined') {
    return initialState.report.adhocCostData;
  }

  if (type === REQUEST_COST_DATA || type === REQUEST_ADHOC_COST_FILE_IMPORT) {
    return {
      ...state,
      loading: true,
      suvc: {},
      vendors: [],
      costDataRecords: [],
      invalidCostDataRecords: []
    };
  }

  if (type === RECEIVE_COST_DATA) {
    return { ...state, costDataRecords: data, loading: false };
  }

  if (type === RECEIVE_ERROR_IN_COST_DATA_FETCH || type === RECEIVE_ERROR_IN_ADHOC_COST_FILE_IMPORT) {
    return { ...state, loading: false };
  }

  if (type === RECEIVE_ADHOC_COST_FILE_IMPORT_RESPONSE) {
    return {
      ...state,
      loading: false,
      suvc: data.suvc,
      vendors: data.vendors,
      costDataRecords: data.validRecords,
      invalidCostDataRecords: data.invalidRecords
    };
  }

  if (type === RESET_ADHOC_COST_DATA) {
    return {
      ...state,
      loading: false,
      suvc: {},
      vendors: [],
      costDataRecords: [],
      invalidCostDataRecords: []
    };
  }

  // Handle item validation related actions
  const handler = itemValidationHandlers[type];
  if (handler) {
    return handler({ state, data });
  }

  return state;
}
