import { call, put, takeEvery } from 'redux-saga/effects';
import agreementApi from '../../externals/agreementProxy';
import { UNABLE_TO_LOAD_AGREEMENT_DATA } from '../../helpers/messageConstant';
import {
  FETCH_AGREEMENT_DATA,
  receiveAgreementData,
  receiveErrorInAgreementFetch,
} from '../actions/agreementActions';
/**
 * Agreement Saga Class
 * @author skit1169
 */
export function* fetchAgreementData({ data }) {
  try {
    const agreementData = yield call(agreementApi.fetchAgreementSearchData, data);
    const agreementDataMap = {};
    agreementDataMap[data.key] = agreementData;
    yield put(receiveAgreementData(agreementDataMap));
  } catch (e) {
    yield put(receiveErrorInAgreementFetch(UNABLE_TO_LOAD_AGREEMENT_DATA));
  }
}

export function* agreementSaga() {
  yield takeEvery(FETCH_AGREEMENT_DATA, fetchAgreementData);
}
