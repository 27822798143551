export const NO_CACHED_SESSION = 'no cached session';
export const UNABLE_TO_REFRESH_SESSION = 'Unable to refresh session';
export const UPLOAD_COUNT_EXCEEDED = 'Please ensure the maximum file count does not exceed 1';
export const PRICE_IMPORT_UPLOAD_LIMIT_EXCEEDED = 'Please ensure the total upload size is below 10MB';
export const UNABLE_TO_LOAD_VENDOR_FILE_IMPORT_DETAILS = 'Unable to load file import details';
export const UNABLE_TO_LOAD_FILE_TRANSFER_DETAILS = 'Unable to load file transfer details';
export const UNABLE_TO_LOAD_USER_DETAILS = 'Unable to load user details';
export const UNABLE_TO_LOAD_USERS = 'Unable to load users';
export const UNABLE_TO_LOAD_AGREEMENT_DATA = 'Unable to load agreement data. Please retry.';
export const UNABLE_TO_LOAD_USER_GROUP_ASSOCIATIONS = 'Error occurred while fetching groups. Please re-login';
export const UNABLE_TO_LOAD_USER_GROUPS = 'Unable to load user groups';
export const UNABLE_TO_SAVE_DETAILS = 'Unable to save details';
export const UNABLE_TO_LOAD_REPORT_DETAILS = 'Unable to load report details';
export const UNABLE_TO_LOAD_REPORT_METADATA = 'Unable to load report metadata';
export const UNABLE_TO_DELETE_TEMPLATE = 'Unable to delete template';
export const UNABLE_TO_LOAD_VENDORS = 'Unable to load vendors';
export const UNABLE_TO_LOAD_ENTERPRISE_COST_MASTER_DATA = 'Unable to load enterprise cost master data';
export const UNABLE_TO_LOAD_ENTERPRISE_COST_DATA = 'Unable to load enterprise item & cost data';
export const UNABLE_TO_LOAD_832_FILES_DETAILS = 'Unable to load 832 BIS files details';
export const UNABLE_TO_FETCH_REPORT_DETAILS = 'Unable to fetch report details';
export const FILE_UPLOAD_ERROR = 'File Upload error. Please try again';
export const PRICE_LOAD_ERROR = 'Unable to Load Prices';
export const UNAUTHORIZED_TO_PERFORM_ACTION = 'You do not have sufficient permissions to perform this action';
export const UNAUTHORIZED_TO_ACCESS_CDM_DASHBOARD = 'You are not authorized to access the CDM Dashboard. Please contact your manager to gain access.';
export const UNABLE_TO_ACCESS_CDM_DASHBOARD = 'Unable to access the CDM Dashboard. Please retry.';
export const S3_FILE_UPLOAD_ERROR = 'S3 File Upload Error';
export const S3_FILE_DOWNLOAD_ERROR = 'S3 File Download Error';
export const INVALID_WORKSPACE_ATTACHMENT_TYPE = 'Invalid attachment type';
export const UNABLE_TO_CREATE_REPORT = 'Unable to create report. Please try again.';
export const UNABLE_TO_LOAD_BUSINESS_CENTERS = 'Unable to load business centers';
export const UNABLE_TO_LOAD_PRICE_RECORD_TYPES = 'Unable to load price record types';
export const SUPPLIER_SUPPORT_REQUEST_SUBMISSION_ERROR = 'Support Request submission error. Please try again';
export const UNSUPPORTED_FILE_FORMAT = 'Unsupported file format. Please import a valid file type';
export const MISSING_REQUIRED_COLUMN_HEADERS = 'Required column headers for the following file(s) are missing.';
export const UNABLE_TO_FETCH_WORKSPACE_METADATA = 'An error occurred during loading. Please refresh.';
export const UNABLE_TO_FETCH_HELP_TOPICS = 'An error occurred. Please refresh.';
export const UNABLE_TO_FETCH_HELP_TOPIC_CONTENT = 'An error occurred during the search. Please try again.';
export const UNABLE_TO_LOAD_REQUEST_DETAILS = 'Unable to load request details';
export const WORKSPACE_REQUEST_SUBMISSION_ERROR = 'Request submission error. Please try again';
export const SUPC_CHARACTER_LENGTH_VALIDATION_ERROR = 'SUPC values must contain 7 characters';
export const DUPLICATE_INPUT_ENTRIES_ERROR = 'Duplicate SUPC values';
export const UNABLE_TO_LOAD_COST_DATA = 'Unable to load cost data. Please try again.';
export const ITEM_VALIDATION_ERROR = 'Item validation error. Please try again.';
export const WORKSPACE_REQUEST_STATUS_UPDATE_ERROR = 'An error occurred during the status update. Please try again.';
export const WORKSPACE_REQUEST_UPDATE_ERROR = 'An error occurred during the update. Please try again.';
export const WORKSPACE_REQUEST_COMMENT_SUBMISSION_ERROR = 'An error occurred when posting the message. Please try again.';
export const FILE_IMPORT_ERROR = 'An error occurred while importing the file. Please try again.';
export const UNABLE_TO_FETCH_WORKSPACE_SEARCH_DATA = 'An error occurred during the search. Please try again.';
export const UNSUPPORTED_CSV_FILE_FORMAT = 'Unsupported File Format. Please Import a valid CSV File.';
export const UNABLE_TO_LOAD_OPCOS = 'Unable to load Opcos';
export const UNABLE_TO_FETCH_EARNED_INCOME_MAPPINGS = 'An error occurred while fetching earned income mapping controls';
export const UNABLE_TO_LOAD_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_DETAILS = 'Unable to load user association and business center contact details';
export const UNABLE_TO_LOAD_SUPPLIER_ONBOARD_DETAILS = 'Unable to load supplier onboard details';
export const UNABLE_TO_UPDATE_SUPPLIER_CONTACT = 'An error occured when updating supplier contact details';
