import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../externals/costMaintenanceProxy';
import {
  VALIDATE_ITEMS_BY_SUPCS,
  receiveItemValidationResponse,
  REQUEST_COST_DATA,
  receiveCostData,
  REQUEST_BUSINESS_CENTER_DETAILS,
  receiveBusinessCenterDetails,
  REQUEST_PRICE_RECORD_TYPES,
  receivePriceRecordTypes,
  REQUEST_ADHOC_COST_FILE_IMPORT,
  SUBMIT_GENERATE_COST_REPORTS,
  receiveAdhocCostFileImportResponse,
  REQUEST_MULTIPLE_ADHOC_COST_FILE_IMPORT,
  receiveMultipleAdhocCostFileImportResponse
} from '../actions/costMaintenanceActions';
import { receiveRequestWithVariationDetails, receiveWorkspaceRequestActionResponse } from '../actions/workspaceActions';
import {
  receiveErrorItemsValidation,
  receiveErrorInCostDataFetch,
  receiveErrorInBusinessCentersFetch,
  receiveErrorInPriceRecordTypesFetch,
  receiveErrorInWorkspaceRequestActionChange,
  receiveErrorInAdhocCostFileImport
} from '../actions/errorDialogActions';
import {
  ITEM_VALIDATION_ERROR,
  UNABLE_TO_LOAD_COST_DATA,
  UNABLE_TO_LOAD_BUSINESS_CENTERS,
  UNABLE_TO_LOAD_PRICE_RECORD_TYPES,
  WORKSPACE_REQUEST_UPDATE_ERROR,
  FILE_IMPORT_ERROR
} from '../../helpers/messageConstant';
/**
 * Cost Maintenance Saga: will be fired on cost data maintenance related actions
 *
 * @author ysum5308
 */
export function* validateItemsBySupcs({ data }) {
  try {
    const responseData = yield call(api.validateItemsBySupcs, data);
    yield put(receiveItemValidationResponse(responseData));
  } catch (e) {
    yield put(receiveErrorItemsValidation(ITEM_VALIDATION_ERROR));
  }
}

export function* fetchCostDataRecords({ data }) {
  try {
    const costDataRecords = yield call(api.fetchCostDataRecords, data);
    yield put(receiveCostData(costDataRecords));
  } catch (e) {
    yield put(receiveErrorInCostDataFetch(UNABLE_TO_LOAD_COST_DATA));
  }
}

export function* importAdhocCostFile({ data }) {
  try {
    const adhocCostFileImportResponse = yield call(api.importAdhocCostFile, data);
    if (adhocCostFileImportResponse.error) {
      yield put(receiveErrorInAdhocCostFileImport(adhocCostFileImportResponse.errorMessage));
    } else {
      yield put(receiveAdhocCostFileImportResponse(adhocCostFileImportResponse));
    }
  } catch (e) {
    yield put(receiveErrorInAdhocCostFileImport(FILE_IMPORT_ERROR));
  }
}

export function* importMultipleAdhocCostFiles({ data }) {
  try {
    const fileProcessList = yield call(
      api.submitVendorPriceImportFiles, data
    );
    yield put(receiveMultipleAdhocCostFileImportResponse(fileProcessList));
  } catch (e) {
    yield put(receiveErrorInAdhocCostFileImport(FILE_IMPORT_ERROR));
  }
}

export function* fetchBusinessCenters({ data }) {
  try {
    const { suvcs } = data;
    const businessCenters = yield call(api.fetchBusinessCenters, suvcs);
    yield put(receiveBusinessCenterDetails(businessCenters));
  } catch (e) {
    yield put(receiveErrorInBusinessCentersFetch(UNABLE_TO_LOAD_BUSINESS_CENTERS));
  }
}

export function* fetchPriceRecordTypes({ data }) {
  try {
    const priceRecordTypes = yield call(api.fetchPriceRecordTypes, data);
    yield put(receivePriceRecordTypes(priceRecordTypes));
  } catch (e) {
    yield put(receiveErrorInPriceRecordTypesFetch(UNABLE_TO_LOAD_PRICE_RECORD_TYPES));
  }
}

function createCostMapsForCostRecordsAndOutputCostRecords(variationResponse) {
  const costImportRecordsMap = {};
  const outputCostRecordsMap = {};
  const { outputCostRecords, costImportRecords } = variationResponse;

  if (outputCostRecords) {
    outputCostRecords.forEach((outputCostRecord) => {
      outputCostRecordsMap[outputCostRecord.lineItemId] = { ...outputCostRecord };
    });
  }
  if (costImportRecords) {
    costImportRecords.forEach((costImportRecord) => {
      const { lineItemId } = costImportRecord;
      costImportRecordsMap[lineItemId] = { ...costImportRecord };
      if (!outputCostRecordsMap[lineItemId]) {
        outputCostRecordsMap[lineItemId] = { ...costImportRecord, costRecordStatus: costImportRecord.status, rejected: true };
      }
    });
  }
  variationResponse.costImportRecordsMap = costImportRecordsMap;
  variationResponse.outputCostRecordsMap = outputCostRecordsMap;
}

export function* submitGenerateCostReports({ data }) {
  const { showConfirmationDialog, rowIdMap, ...params } = data;
  try {
    const variationResponse = yield call(api.submitGenerateCostReports, params);
    createCostMapsForCostRecordsAndOutputCostRecords(variationResponse);
    yield put(receiveRequestWithVariationDetails({
      ...variationResponse, rowIdMap, isVariationReportGenerated: true
    }));
    if (showConfirmationDialog) {
      yield put(receiveWorkspaceRequestActionResponse({ ...variationResponse, message: 'Successfully triggered cost report generation' }));
    }
  } catch (e) {
    yield put(receiveErrorInWorkspaceRequestActionChange(WORKSPACE_REQUEST_UPDATE_ERROR));
  }
}

export function* costMaintenanceSaga() {
  yield takeEvery(VALIDATE_ITEMS_BY_SUPCS, validateItemsBySupcs);
  yield takeEvery(REQUEST_COST_DATA, fetchCostDataRecords);
  yield takeEvery(REQUEST_ADHOC_COST_FILE_IMPORT, importAdhocCostFile);
  yield takeEvery(REQUEST_MULTIPLE_ADHOC_COST_FILE_IMPORT, importMultipleAdhocCostFiles);
  yield takeEvery(REQUEST_BUSINESS_CENTER_DETAILS, fetchBusinessCenters);
  yield takeEvery(REQUEST_PRICE_RECORD_TYPES, fetchPriceRecordTypes);
  yield takeEvery(SUBMIT_GENERATE_COST_REPORTS, submitGenerateCostReports);
}
