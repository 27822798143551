/**
 * @author skit1169
 * */

/**
 * Action Types
 */
export const RECEIVE_AGREEMENT_DATA = 'RECEIVE_AGREEMENT_DATA';
export const FETCH_AGREEMENT_DATA = 'FETCH_AGREEMENT_DATA';
export const RESET_AGREEMENT_DATA = 'RESET_AGREEMENT_DATA';
export const RECEIVE_ERROR_IN_AGREEMENT_FETCH = 'RECEIVE_ERROR_IN_AGREEMENT_FETCH';

/**
 * Actions
 */
export const fetchAgreementData = (data) => ({ type: FETCH_AGREEMENT_DATA, data });
export const receiveAgreementData = (data) => ({ type: RECEIVE_AGREEMENT_DATA, data });
export const resetAgreementData = () => ({ type: RESET_AGREEMENT_DATA });
export const receiveErrorInAgreementFetch = (data) => ({ type: RECEIVE_ERROR_IN_AGREEMENT_FETCH, data });
